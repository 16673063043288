<template>
  <div
    class="my-3"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pl-9 pr-8' : 'px-5'" style="margin-left: 12%;">

    <!-- ==== DIALOG PILIH TOKO ==== -->
    <v-dialog v-model="dialog.selectStore" persistent max-width="800">
      <v-card rounded="lg">
        <v-card-text>
          <v-btn
            v-show="event_selected != null"
            class="mt-2 mr-5"
            @click="dialog.selectStore = false"
            icon
            absolute
            right
          >
            <v-icon color="white"> mdi-close-circle </v-icon>
          </v-btn>
          <div
            style="background-color: #d31145; height: 7vh"
            class="rounded-b-xl d-flex justify-center align-center"
            width="100%"
          >
            <span class="white--text overline">Pilih Event</span>
          </div>
        </v-card-text>
        <v-card-text>
          <v-container class="d-flex justify-center align-center">
            <v-slide-group
              v-model="model"
              class="pa-4"
              mandatory
              center-active
              show-arrows
            >
              <v-slide-item
                transition="slide-y-transition"
                v-for="(item, index) in event"
                :key="index"
              >
                <v-card
                  color="#ecf0f1"
                  class="ma-4 elevation-0 rounded-lg c-p"
                  v-ripple
                  width="250"
                  @click="updateEvent(item)"
                >
                  <v-container>
                    <v-row class="fill-height justify-center">
                      <v-col class="d-flex justify-center" cols="12">
                        <v-avatar tile size="130">
                          <v-img class="rounded-lg" :src="item.image_url ? item.image_url : require('@/assets/image/planner.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0 justify-center"
                                align="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#d31145"
                                >
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="12">
                        <div class="text-header-1 text-center">
                          <b>{{ item.name }}</b>
                        </div>
                        <div class="text-header-1 text-center">
                          <i>{{ item.start_date | dateFull }} - {{ item.end_date | dateFull }}</i>
                        </div>
                        <p
                          class="caption grey--text text-center address-text"
                          v-html="item.address">
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== FILTER SUMMARY ==== -->
    <v-row justify="start" class="mb-5" v-show="!dialog.state">
      <v-col cols="12" md="4">
        <div class="text-left mr-3">
          <v-sheet :width="$vuetify.breakpoint.name === 'xs' ? 200 : 300">
            <v-list-item
              dense
              @click="storeDisabled"
              class="py-0 pt-0 pb-0 pl-1 my-0"
              style="
                background-color: #d31145;
                border-color: #d31145;
                border-radius: 50px;
                font-size: 19px;
              "
            >
              <v-list-item-avatar
                class="my-0"
                :style="
                $vuetify.breakpoint.name === 'xs' ? 
                'height: 32px; min-width: 32px; width: 32px; border: 3px solid rgb(255, 255, 255); background-color: white;'
                : 
                  'height: 32px; min-width: 32px; width: 32px; border: 3px solid rgb(255, 255, 255); background-color: white;'
                "
              >
                <v-img :src="event_image_url">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 justify-center"
                      align="center">
                      <v-progress-circular indeterminate color="#d31145">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content class="py-0 mt-0">
                <v-list-item-title class="white--text pt-0">
                  {{ eventName }} 
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon :class="$vuetify.breakpoint.name === 'xs' ? 'ml-0' : ''">
                <v-icon color="white" right>mdi-bank-transfer</v-icon>
                <!-- <v-icon color="white" right>mdi-swap-horizontal</v-icon> -->
              </v-list-item-icon>
            </v-list-item>
          </v-sheet>
        </div>
      </v-col>
      <!-- <v-col>
        <v-btn
          class="text-capitalize white--text"
          elevation="0"
          color="#d31145"
          to="/invitation">
          Invite Merchant
          <v-icon right>mdi-email-arrow-right-outline</v-icon>
        </v-btn>
      </v-col> -->
    </v-row>

    <div style="height: calc(100vh - 180px); overflow-y: auto; overflow-x: hidden;" v-show="!dialog.state">

      <!-- ==== DATA SUMMARY PENJUALAN ==== -->
      <v-row>
        <v-col>
          <v-card outlined class="box-shadow border-radius pa-5 pb-1" min-height="300">
            <v-card-title class="body-1 px-5 text-third font-weight-bold pt-0">
              Realtime Transaksi Merchant
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                elevation="0"
                color="#1B59F8"
                small
                text
                @click="openDialog('realtime')">
                Selengkapnya
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle class="caption pl-5 text-third">
              <b>{{ filtered }}</b>
            </v-card-subtitle>
            <v-card-text>
              <v-simple-table v-if="sales_summary.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="color: #FA91AD; width: 15%;">
                        Nama Merchant
                      </th>
                      <th class="text-left" style="color: #FA91AD;">
                        Produk
                      </th>
                      <th class="text-right" style="color: #FA91AD; width: 25%;">
                        Nominal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in sales_summary"
                      :key="i">
                      <td>
                        <v-list-item
                          dense
                          class="pl-1">
                          <!-- <v-list-item-avatar
                            :style="
                            $vuetify.breakpoint.name === 'xs' ? 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            : 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            ">
                            <v-img :src="item.merchant_image">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0 justify-center"
                                  align="center">
                                  <v-progress-circular indeterminate color="#d31145">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar> -->
                          <v-list-item-content style="max-width: 220px">
                            <v-list-item-title class="text-first">
                              {{ item.store_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-third">
                              {{ item.store_address ? item.store_address : "-" }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </td>
                      <td class="text-first">{{ item.product_name }}</td>
                      <td class="text-first text-right">{{ item.grand_total | price }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else class="text-center subtitle-2">
                <v-img
                  contain
                  class="mx-auto mb-3"
                  width="150"
                  :src="require('@/assets/icon/img_empty_data.png')">
                </v-img>
                <div>
                  Data transaksi belum tersedia
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined class="box-shadow border-radius pa-5 pb-1"  min-height="300">
            <v-card-title class="body-1 px-5 text-third font-weight-bold pt-0">
              Count Data Personal Buyer
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                elevation="0"
                color="#1B59F8"
                small
                text
                @click="openDialog('buyer')">
                Selengkapnya
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle class="caption pl-5 text-third">
              <b>{{ filtered }}</b>
            </v-card-subtitle>
            <v-card-text>
              <v-simple-table v-if="personal_buyer.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="color: #FA91AD">
                        Nama Pelanggan
                      </th>
                      <th class="text-left" style="color: #FA91AD">
                        Total Transaksi
                      </th>
                      <th class="text-center" style="color: #FA91AD">
                        Jumlah Pembelian
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in personal_buyer"
                      :key="i">
                      <td>
                        <v-list-item
                          dense
                          class="pl-1">
                          <!-- <v-list-item-avatar
                            :style="
                            $vuetify.breakpoint.name === 'xs' ? 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            : 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            ">
                            <v-img :src="item.merchant_image">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0 justify-center"
                                  align="center">
                                  <v-progress-circular indeterminate color="#d31145">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar> -->
                          <v-list-item-content style="max-width: 220px">
                            <v-list-item-title class="text-first">
                              {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-third">
                              {{ item.store_name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </td>
                      <td class="text-first">{{ item.transaction_total | price }}</td>
                      <td class="text-first text-center">{{ item.transaction_count | num_format }} Transaksi</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else class="text-center subtitle-2">
                <v-img
                  contain
                  class="mx-auto mb-3"
                  width="150"
                  :src="require('@/assets/icon/img_empty_data.png')">
                </v-img>
                <div>
                  Data transaksi belum tersedia
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" xl="4">
          <v-card outlined class="box-shadow border-radius pa-5 pb-1" min-height="300">
            <v-card-title class="body-1 px-5 text-third font-weight-bold pt-0">
              Rank Sales Merchant
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                elevation="0"
                color="#1B59F8"
                small
                text
                @click="openDialog('sales')">
                Selengkapnya
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle class="caption pl-5 text-third">
              <b>{{ filtered }}</b>
            </v-card-subtitle>
            <v-card-text>
              <v-simple-table v-if="rank_sales_merchant.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="color: #FA91AD">
                        Nama Merchant
                      </th>
                      <th class="text-center" style="color: #FA91AD">
                        Jumlah Transaksi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in rank_sales_merchant"
                      :key="i">
                      <td>
                        <v-list-item
                          dense
                          class="pl-1">
                          <!-- <v-list-item-avatar
                            :style="
                            $vuetify.breakpoint.name === 'xs' ? 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            : 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            ">
                            <v-img :src="item.merchant_image">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0 justify-center"
                                  align="center">
                                  <v-progress-circular indeterminate color="#d31145">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar> -->
                          <v-list-item-content style="max-width: 220px">
                            <v-list-item-title class="text-first">
                              {{ item.store_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-third">
                              {{ item.store_address }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </td>
                      <td class="text-first text-center"><span class="font-weight-bold">{{ item.transaction_count | num_format }}</span> Transaksi</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else class="text-center subtitle-2">
                <v-img
                  contain
                  class="mx-auto mb-3"
                  width="150"
                  :src="require('@/assets/icon/img_empty_data.png')">
                </v-img>
                <div>
                  Data transaksi belum tersedia
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" xl="4">
          <v-card outlined class="box-shadow border-radius pa-5 pb-1" min-height="300">
            <v-card-title class="body-1 px-5 text-third font-weight-bold pt-0">
              Rank Product Category
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                elevation="0"
                color="#1B59F8"
                small
                text
                @click="openDialog('product_category')">
                Selengkapnya
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle class="caption pl-5 text-third">
              <b>{{ filtered }}</b>
            </v-card-subtitle>
            <v-card-text>
              <v-simple-table v-if="rank_product_category.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="color: #FA91AD">
                        Nama Kategori Produk
                      </th>
                      <th class="text-center" style="color: #FA91AD">
                        Jumlah Terjual
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in rank_product_category"
                      :key="i">
                      <td>
                        <v-list-item
                          dense
                          class="pl-1">
                          <!-- <v-list-item-avatar
                            :style="
                            $vuetify.breakpoint.name === 'xs' ? 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            : 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            ">
                            <v-img :src="item.product_category_image">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0 justify-center"
                                  align="center">
                                  <v-progress-circular indeterminate color="#d31145">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar> -->
                          <v-list-item-content style="max-width: 220px">
                            <v-list-item-title class="text-first">
                              {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-third">
                              {{ item.store_name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </td>
                      <td class="text-first text-center"><span class="font-weight-bold">{{ item.transaction_count | num_format }}</span> Terjual</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else class="text-center subtitle-2">
                <v-img
                  contain
                  class="mx-auto mb-3"
                  width="150"
                  :src="require('@/assets/icon/img_empty_data.png')">
                </v-img>
                <div>
                  Data transaksi belum tersedia
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" xl="4">
          <v-card outlined class="box-shadow border-radius pa-5 pb-1" min-height="300">
            <v-card-title class="body-1 px-5 text-third font-weight-bold pt-0">
              Rank Item Product
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                elevation="0"
                color="#1B59F8"
                small
                text
                @click="openDialog('product')">
                Selengkapnya
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle class="caption pl-5 text-third">
              <b>{{ filtered }}</b>
            </v-card-subtitle>
            <v-card-text>
              <v-simple-table v-if="rank_item_product.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="color: #FA91AD">
                        Nama Produk
                      </th>
                      <th class="text-center" style="color: #FA91AD">
                        Jumlah Terjual
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in rank_item_product"
                      :key="i">
                      <td>
                        <v-list-item
                          dense
                          class="pl-1">
                          <!-- <v-list-item-avatar
                            :style="
                            $vuetify.breakpoint.name === 'xs' ? 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            : 
                              'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(255, 255, 255);'
                            ">
                            <v-img :src="item.product_image">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0 justify-center"
                                  align="center">
                                  <v-progress-circular indeterminate color="#d31145">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar> -->
                          <v-list-item-content style="max-width: 220px">
                            <v-list-item-title class="text-first">
                              {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-third">
                              {{ item.store_name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </td>
                      <td class="text-first text-center"><span class="font-weight-bold">{{ item.transaction_count | num_format }}</span> {{ item.unit }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else class="text-center subtitle-2">
                <v-img
                  contain
                  class="mx-auto mb-3"
                  width="150"
                  :src="require('@/assets/icon/img_empty_data.png')">
                </v-img>
                <div>
                  Data transaksi belum tersedia
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- ==== DATA CHART GRAFIK ==== -->
      <v-row>
        <v-col cols="12" md="12" class="mt-6">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.run"
            transition="scale-transition"
            :tile="true"
            type="card"
          >
            <v-card 
              outlined 
              class="box-shadow border-radius py-5">
              <v-card-title class="body-1 px-5 text-second pt-0">
                Total Transaksi
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-subtitle class="caption px-5 text-third">
                <b>{{ filtered }}</b>
              </v-card-subtitle>
              <div class="mt-8 pa-3">
                <div v-if="Object.keys(total_transaction).length > 0">
                  <apexchart
                    height="300"
                    :options="total_transaction.chartOptions"
                    :series="total_transaction.series"
                  >
                  </apexchart>
                </div>
                <div
                  class="title text-second d-flex align-center justify-center"
                  v-if="total_transaction.series == []"
                  style="height: 200px;"
                >
                  Data Belum Tersedia
                </div>
              </div>
            </v-card>
          </v-skeleton-loader>
        </v-col>
      </v-row>

    </div>

    <div style="height: calc(100vh - 100px); overflow: auto;" v-show="dialog.state">

      <DashboardDetail 
        v-if="dialog.state"
        :showDetail="dialog.state" 
        :type="dialog.tipe" 
        :url="urls"
        :title="dialog.title" 
        :headers="headersTable(dialog.tipe)" 
        :filtered="filtered" @isShow="showDialog()" 
        @close="closeDialogs"/>

    </div>

  </div>
</template>

<script>
import { get, post, put, destroy } from "../../service/Axios";
import moment from "moment";
import Empty from "@/components/Empty";
import Export from "@/components/Export";
import DashboardDetail from "@/components/DashboardDetails";
import { TokenService } from "@/service/Storage.Service";
export default {
  components: {
    Empty,
    Export,
    DashboardDetail,
  },
  data() {
    return {
      filter: {
        filterdate: null,
        dateNow: new Date().toISOString().substr(0, 10),
        event_id: 0
      },
      model: null,
      getterCount: false,
      event_image_url: "",
      urls: "",
      process: {
        run: false
      },
      dialog: {
        state: false,
        title: "",
        tipe: "",
        selectStore: false,
      },
      itemsTable: [],
      emptyData: false,
      sales_summary: [],
      personal_buyer: [],
      rank_sales_merchant: [],
      rank_product_category: [],
      rank_item_product: [],
      total_transaction: {}
    }
  },
  computed: {
    eventName() {
      let name = this.event_selected.name;
      this.event_image_url = this.event_selected.image_url ? this.event_selected.image_url : require('@/assets/icon/calendar.png')
      return name;
    },
    filtered() {
      let event = JSON.parse(localStorage.getItem("event_selected"));
      return "Event berlangsung : " + moment(event.start_date).format('DD MMMM YYYY') + ' - ' + moment(event.end_date).format('DD MMMM YYYY')
    },
    event() {
      return JSON.parse(localStorage.getItem("event"))
    },
    event_selected() {
      return JSON.parse(localStorage.getItem("event_selected"))
    },
    user() {
      return JSON.parse(localStorage.getItem("access_user"))
    }
  },
  watch: {},
  beforeMount() {
    this.getAllData()
    // this.getChartTransaction()
  },
  mounted() {
    let _self = this;
    setInterval(() => {
      console.log('running');
      this.getAllData()
    }, 10000);
  },
  methods: {
    storeDisabled() {
      this.dialog.selectStore = true;
    },
    updateEvent(selected) {
      localStorage.setItem('event_selected', JSON.stringify(selected))
      this.dialog.selectStore = false;
      window.location.reload();
    },
    getAllData(){
      this.filter.event_id = JSON.parse(localStorage.getItem('event_selected')).id
      setTimeout( async () => {
        await this.getRealtimeTransaksi()
        await this.getCountDataBuyer()
        await this.getRankSales()
        await this.getRankProductCategory()
        await this.getRankItemProduct()
        await this.getChartTransaction()
      }, 500);
    },
    showDialog(state){
      this.dialog.state = state;
    },
    closeDialogs(state) {
      this.dialog.state = state;
    },
    openDialog(type) {
      this.dialog.tipe = type;
      this.itemsTable = []
      this.headersTable(type)
      switch (type) {
        case "realtime":
          this.showDialog(true)
          this.urls = `eo/report/sales_order?event_id=${this.filter.event_id}&sort=-date`
          this.dialog.state = true;
          this.dialog.title = "Realtime Transaksi Merchant"
          this.getRealtimeTransaksi()
          break;
        case "buyer":
          this.urls = `eo/report/customer?event_id=${this.filter.event_id}&sort=transaction_count`
          this.dialog.state = true;
          this.dialog.title = "Count Data Personal Buyer"
          this.getCountDataBuyer()
          break;
        case "sales":
          this.urls = `eo/report/store?event_id=${this.filter.event_id}&sort=transaction_count`
          this.dialog.state = true;
          this.dialog.title = "Rank Sales Merchant"
          this.getRankSales()
          break;
        case "product_category":
          this.urls = `eo/report/product/category?event_id=${this.filter.event_id}&sort=transaction_count`
          this.dialog.state = true;
          this.dialog.title = "Rank Product Category"
          this.getRankProductCategory()
          break;
        case "product":
          this.urls = `eo/report/product?event_id=${this.filter.event_id}&sort=transaction_count`
          this.dialog.state = true;
          this.dialog.title = "Rank Item Product"
          this.getRankItemProduct()
          break;
      }
    },
    headersTable(type) {
      let headers = []
      switch(type) {
        case "realtime":
          headers = [
            {
              text: "Nama Merchant",
              value: "merchant_name",
              align: "start"
            },
            {
              text: "Nama Produk",
              value: "product_name_realtime",
              align: "start"
            },
            {
              text: "Nominal",
              value: "product_price",
              align: "end"
            },
            // {
            //   text: "Tanggal",
            //   value: "created_at",
            //   align: "center"
            // },
          ]
          break;
        case "buyer":
          headers = [
            {
              text: "Nama Pelanggan",
              value: "customer_name",
              align: "start"
            },
            {
              text: "Total Transaksi",
              value: "total_amount",
              align: "start"
            },
            {
              text: "Tanggal",
              value: "created_at",
              align: "center"
            },
          ]
          break;
        case "sales":
          headers = [
            {
              text: "Nama Merchant",
              value: "merchant_name",
              align: "start"
            },
            {
              text: "Jumlah Transaksi",
              value: "transaction_total",
              align: "start"
            },
            {
              text: "Total Penjualan",
              value: "transaction_count",
              align: "start"
            },
            // {
            //   text: "Tanggal",
            //   value: "created_at",
            //   align: "center"
            // },
          ]
          break;
        case "product_category":
          headers = [
            {
              text: "Kategori Produk",
              value: "product_category_name",
              align: "start"
            },
            {
              text: "Jumlah Transaksi",
              value: "transaction_total",
              align: "start"
            },
            {
              text: "Total Penjualan",
              value: "transaction_count",
              align: "start"
            }
          ]
          break;
        case "product":
          headers = [
            {
              text: "Nama Produk",
              value: "product_name",
              align: "start"
            },
            {
              text: "Jumlah Transaksi",
              value: "transaction_total",
              align: "start"
            },
            {
              text: "Total Penjualan",
              value: "transaction_count_product",
              align: "start"
            }
          ]
          break;
      }

      return headers
    },
    async getRealtimeTransaksi(){
      await get(`eo/report/sales_order`, {
        params: {
          event_id: this.event_selected.id,
          limit: 3,
          sort: '-date'
        }
      }).then(response => {
        let res = response.data
        if (res.status == 200) {
          this.sales_summary = res.results.data
        }
      })
      // this.itemsTable = this.sales_summary
    },
    async getCountDataBuyer(){
      await get(`eo/report/customer`, {
        params: {
          event_id: this.event_selected.id,
          limit: 3,
          sort: 'transaction_count'
        }
      }).then(response => {
        let res = response.data
        if (res.status == 200) {
          this.personal_buyer = res.results.data
        }
      })
      // this.itemsTable = this.personal_buyer
    },
    async getRankSales(){
      await get(`eo/report/store`, {
        params: {
          event_id: this.event_selected.id,
          limit: 3,
          sort: 'transaction_count'
        }
      }).then(response => {
        let res = response.data
        if (res.status == 200) {
          this.rank_sales_merchant = res.results.data
        }
      })
      // this.itemsTable = this.rank_sales_merchant
    },
    async getRankProductCategory(){
      await get(`eo/report/product/category`, {
        params: {
          event_id: this.event_selected.id,
          limit: 3,
          sort: 'transaction_count'
        }
      }).then(response => {
        let res = response.data
        if (res.status == 200) {
          this.rank_product_category = res.results.data
        }
      })
      // this.itemsTable = this.rank_product_category
    },
    async getRankItemProduct() {
      await get(`eo/report/product`, {
        params: {
          event_id: this.event_selected.id,
          limit: 3,
          sort: 'transaction_count'
        }
      }).then(response => {
        let res = response.data
        if (res.status == 200) {
          this.rank_item_product = res.results.data
        }
      })
      // this.itemsTable = this.rank_item_product
    },
    async getChartTransaction() {
      await get(`eo/report/chart`, {
        params: {
          event_id: this.event_selected.id,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            // PENJUALAN KOTOR HARIAN
            this.total_transaction = {
              series: [
                {
                  name: "Total Transaksi",
                  type: "column",
                  data: [],
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000,
                    },
                  },
                  toolbar: {
                    show: true,
                    export: {
                      csv: {
                        filename: "Total Transaksi",
                      },
                      svg: {
                        filename: "Total Transaksi",
                      },
                      png: {
                        filename: "Total Transaksi",
                      }
                    }
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: [1, 3],
                },
                colors: ["#649fe4", "#41af55"],
                labels: [],
                xaxis: {
                  title: {
                    text: "Hari",
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = "";
                      const reverseNumber = val
                        .toString()
                        .split("")
                        .reverse()
                        .join("");
                      const arrReverseNumber = [
                        ...Array(reverseNumber.length).keys(),
                      ];
                      arrReverseNumber.map((index) => {
                        if (index % 3 === 0)
                          price += reverseNumber.substr(index, 3) + ".";
                      });
                      return `Rp ${price
                        .split("", price.length - 1)
                        .reverse()
                        .join("")}`;
                    },
                    style: {
                      color: undefined,
                      fontSize: "8px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  },
                },
              },
            };

            this.total_transaction.chartOptions.labels = res.results.data.label;
            this.total_transaction.series[0].data = res.results.data.value;

            this.process.run = false;
          }
        })
    },
  },
};
</script>

<style>
.line {
  border-left: 6px solid #d31145 !important;
  transition: 0.3s;
}
</style>
